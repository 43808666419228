<template>
  <div v-if="!Object.values(isDataReady).every(v => v === true)" class="flex flex-col px-6 py-4">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
  <div v-else>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <label class="flex items-center">
        <span class="mr-2 text-sm font-semibold text-gray-200">Chain</span>
      </label>
      <div class="pointer-events-none opacity-80">
        <BaseSelect
          dropdown-width-class="w-full"
          button-width-class="w-full"
          :placeholder="'Select Chain'"
          v-model="alertData.chain"
          @update:model-value="fetchProtocols"
          :options="allChains"
        />
        <div class="pt-1 text-xs text-gray-400">* Currently the only supported chain.</div>
      </div>
    </div>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <label class="text-sm font-semibold text-gray-200">Categories</label>
      <!-- <BaseRadio
        v-model="selectedMode"
        @update:model-value="resetAlertData"
        :options="['Coins', 'NFTs']"
        class="my-1"
      /> -->
      <div
        class="cursor-pointer rounded p-2 px-3 text-sm font-medium text-gray-100 hover:bg-blue-900 hover:text-gray-100"
        :class="alertData.type == metric.id ? 'bg-blue-900' : 'bg-gray-800'"
        v-for="metric in alertMetrics"
        @click="
          alertData.type = metric.id;
          alertData.protocols = [];
        "
        :key="metric.label"
      >
        <div>{{ metric.label }}</div>
        <div class="text-xs" :class="alertData.type == metric.id ? 'text-gray-100' : 'text-gray-400'">
          {{ metric.description }}
        </div>
      </div>
    </div>
    <div v-if="alertData.type == 'dex_trade'" class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex cursor-pointer justify-between" @click="toggles.protocol = !toggles.protocol">
        <div class="flex items-center space-x-2">
          <label class="text-sm font-semibold text-gray-200">
            Protocol<span class="ml-2 font-medium text-gray-400">(optional)</span>
          </label>
          <span v-if="required.alertType" class="mt-0.5 text-xs text-red-400">{{ errorStatement }}</span>
        </div>
        <ChevronDownIcon
          :class="[toggles.protocol ? 'rotate-180 duration-100' : '', 'text-gray-400transform ml-1 h-5 w-5']"
          @click="$emit('click')"
        />
      </div>
      <div class="flex flex-col space-y-2" v-if="toggles.protocol">
        <BaseSelect
          v-model="alertData.protocols"
          multiple
          searchable
          :options="protocolOptions"
          placeholder="Select Decentralized Exchanges to Track"
          button-width-class="w-full"
          dropdown-width-class="w-full"
        />
      </div>
    </div>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex cursor-pointer justify-between" @click="toggles.address = !toggles.address">
        <div class="flex items-center space-x-2">
          <label class="text-sm font-semibold text-gray-200">
            Address<span class="ml-2 font-medium text-gray-400">(optional)</span>
          </label>
          <span v-if="required.alertType" class="mt-0.5 text-xs text-red-400">{{ errorStatement }}</span>
          <span v-else-if="invalidAddress" class="mt-0.5 text-xs text-red-400"> Please enter a valid address </span>
        </div>
        <ChevronDownIcon
          :class="[toggles.address ? 'rotate-180 duration-100' : '', 'ml-1 h-5 w-5 transform text-gray-400']"
          @click="$emit('click')"
        />
      </div>
      <div class="flex flex-col space-y-2" v-if="toggles.address">
        <div class="flex justify-between">
          <BaseRadio
            v-model="alertData.addresses.tag"
            class="my-1"
            :options="['Address', 'All Tags', 'My Tags', 'Folders', 'Exchanges', 'ENS']"
            @update:model-value="resetAlertAddress"
          />
        </div>
        <BaseTextbox
          v-if="alertData.addresses.tag == 'Address'"
          v-model="alertData.addresses.address"
          placeholder="Enter Wallet Address to Track"
        />
        <BaseSelect
          v-else-if="alertData.addresses.tag == 'Folders'"
          :options="allFolders"
          placeholder="Select Folder(s)"
          v-model="selectedFolders"
          @update:model-value="updateAlertAddresses($event, 'folders')"
          dropdown-width-class="w-full"
          button-width-class="w-full"
          searchable
          multiple
        />
        <BaseSelect
          v-else-if="alertData.addresses.tag == 'My Tags'"
          button-width-class="w-full"
          v-model="selectedAddressTags"
          dropdown-width-class="w-full"
          placeholder="Select Tag(s)"
          :options="myTags"
          :search-options="['label', 'address']"
          @update:model-value="updateAlertAddresses($event, 'address_tags')"
          searchable
          multiple
        />
        <BaseSelect
          v-else-if="alertData.addresses.tag == 'Exchanges'"
          button-width-class="w-full"
          v-model="selectedAddressTags"
          dropdown-width-class="w-full"
          placeholder="Select Exchange(s)"
          :options="exchangeTags"
          :search-options="['label', 'address']"
          @update:model-value="updateAlertAddresses($event, 'address_tags')"
          searchable
          multiple
        />
        <DropdownAsyncSelect
          v-else
          v-model="selectedAddressTags"
          dropdown-width-class="w-full"
          button-width-class="w-full"
          :key="alertData.addresses.tag"
          :placeholder="'Select Tag(s)'"
          :sub-text="alertData.addresses.tag == 'All Tags' ? 'category' : null"
          :multiple="true"
          :show-selected="true"
          :pre-selected-options="alertData.addresses.address_tags"
          :async-options="fetchTags"
          @update:model-value="updateAlertAddresses($event, 'address_tags')"
        />
      </div>
    </div>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex cursor-pointer justify-between" @click="toggles.coins = !toggles.coins">
        <div class="flex items-center space-x-2">
          <label class="cursor-pointer text-sm font-semibold text-gray-200"
            >{{ selectedMode == 'Coins' ? 'Coins' : 'Collections'
            }}<span class="ml-2 font-medium text-gray-400">(optional)</span></label
          >
          <span v-if="required.alertType" class="mt-0.5 text-xs text-red-400">{{ errorStatement }}</span>
        </div>
        <ChevronDownIcon
          :class="[toggles.coins ? 'rotate-180 duration-100' : '', 'ml-1 h-5 w-5 transform text-gray-400']"
          @click="$emit('click')"
        />
      </div>
      <div class="flex flex-col space-y-2" v-if="toggles.coins">
        <BaseRadio
          v-model="selectedAlertTag"
          :key="selectedMode"
          :options="Object.values(selectedMode == 'Coins' ? coinAlertTags : nftAlertTags)"
          class="my-1"
        />
        <div v-if="selectedMode == 'Coins'">
          <div v-if="selectedAlertTag === 'Coins'">
            <DropdownSelectCoin
              :display-count="8"
              :starting-coins="preSelectedCoins"
              :supported-coins="selectedChainCoins"
              v-model="alertData.coins"
              dropdown-width-class="w-full"
              button-width-class="w-full"
              @update="alertData.coins = $event"
              placeholder="Select Coin(s)"
              id="coin-dropdown"
            />
          </div>
          <div v-else-if="selectedAlertTag === 'Watchlist'">
            <BaseSelect
              dropdown-width-class="w-full"
              button-width-class="w-full"
              placeholder="Select Watchlist"
              :searchable="true"
              v-model="alertData.watchlist.id"
              :options="filteredWatchlists"
            />
          </div>
          <div v-else-if="selectedAlertTag === 'Category'">
            <BaseSelect
              button-width-class="w-full"
              dropdown-width-class="w-full"
              placeholder="Select Category"
              :searchable="true"
              v-model="alertData.category.id"
              :options="filteredCategories"
            />
          </div>
          <div v-else-if="selectedAlertTag === 'Subcategory'">
            <BaseSelect
              button-width-class="w-full"
              dropdown-width-class="w-full"
              placeholder="Select Subcategory"
              :searchable="true"
              v-model="alertData.subcategory.id"
              :options="filteredSubcategories"
            />
          </div>
        </div>
        <div v-else>
          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-full"
            placeholder="Select Collections(s)"
            :searchable="true"
            v-model="alertData.collections"
            :options="allCategories"
            multiple
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex cursor-pointer justify-between" @click="toggles.value = !toggles.value">
        <div class="flex items-center space-x-2">
          <label class="text-sm font-semibold text-gray-200"
            >Value Threshold<span class="ml-2 font-medium text-gray-400">(optional)</span></label
          >
        </div>
        <ChevronDownIcon
          :class="[toggles.value ? 'rotate-180 duration-100' : '', 'ml-1 h-5  w-5 transform text-gray-400']"
          @click="$emit('click')"
        />
      </div>
      <span v-if="toggles.value" class="text-xs text-gray-400"
        >Input a price or value threshold for the alert to trigger.</span
      >
      <div class="flex items-center space-x-2" v-if="toggles.value">
        <div
          class="flex h-8 w-10 items-center justify-center rounded border border-solid border-gray-700 bg-gray-800/50 px-2 text-xs text-gray-400"
        >
          <span>></span>
        </div>
        <BaseNumberInput v-model="alertData.value" :key="selectedAlertTag" class="w-full">
          <template #left>$</template>
        </BaseNumberInput>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import isEqual from 'lodash/isEqual';
import useHttp from '@/composeables/http';
import capitalize from 'lodash/capitalize';
import useEmitter from '@/composeables/emitter';
import { getAddresses } from '@/api/address-api';
import { ref, computed, onMounted, watch, onBeforeMount } from 'vue';
import DropdownSelectCoin from '@/components/dropdown/DropdownSelectCoin.vue';
import DropdownAsyncSelect from '@/components/dropdown/DropdownAsyncSelect.vue';
import { useWatchlistStore } from '@/stores/watchlists';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';

const $store = useStore();
const $watchlistStore = useWatchlistStore();
const $http = useHttp();
const $emitter = useEmitter();

const props = defineProps({
  alert: {
    type: Object,
    default: () => null
  }
});

//ALERT DATA
const range = ref('>');
const alertData = ref({
  type: 'coin_transfer_all',
  chain: 'ethereum',
  protocols: [],
  addresses: {
    tag: 'Address',
    address: null,
    address_tags: [],
    folders: []
  },
  coins: [],
  watchlist: { id: null },
  category: { id: null },
  subcategory: { id: null },
  value: null,
  collections: []
});
watch(
  () => alertData,
  () => {
    let config = {};
    for (const [k, v] of Object.entries(alertData.value)) {
      if (['watchlist', 'category', 'subcategory'].includes(k)) {
        if (v && (v.uid != null || v.id != null)) {
          config[k] = v;
        }
      } else if (['coins', 'collections', 'protocols'].includes(k)) {
        if (v && v.length > 0) {
          config[k] = v;
        }
      } else {
        config[k] = v;
      }
    }
    $emitter.$emit('config:alert', { config: config });
  },
  { deep: true },
  { immediate: true }
);
function resetAlertAddress() {
  alertData.value.addresses = {
    tag: alertData.value.addresses.tag,
    address: null,
    address_tags: [],
    folders: []
  };
}

//PROTOCOLS
const protocolOptions = ref([]);
onMounted(() => {
  fetchProtocols();
});
async function fetchProtocols() {
  const resp = await $http.get('/data/onchain_protocols', { params: { chain: alertData.value.chain } });
  protocolOptions.value = resp?.data?.protocols?.map(x => {
    return { id: x.protocol, label: x.protocol };
  });
}

//ALERT CATEGORIES METRICS
const coinAlertMetrics = [
  {
    id: 'coin_transfer_all',
    label: 'Coin Transfers: All',
    description: 'Displays all token transfers to and from a specifed address'
  },
  {
    id: 'coin_transfer_to',
    label: 'Coin Transfers: To',
    description: 'Displays all token transfers to a specifed address'
  },
  {
    id: 'coin_transfer_from',
    label: 'Coin Transfers: From',
    description: 'Displays all token transfers from a specifed address'
  },
  {
    id: 'dex_trade',
    label: 'DEX Swaps',
    description: 'Displays all DEX swaps to and from a specifed address'
  }
];
const nftAlertMetrics = [
  {
    id: 'nft_transfers_all',
    label: 'NFT Transfers: All',
    description: 'Displays all NFT transfers to and from a specifed address'
  },
  {
    id: 'nft_transfers_to',
    label: 'NFT Transfers: To',
    description: 'Displays all NFT transfers to a specifed address'
  },
  {
    id: 'nft_transfers_from',
    label: 'NFT Transfers: From',
    description: 'Displays all NFT transfers from a specifed address'
  },
  {
    id: 'market_place_trades',
    label: 'Marketplace Trades',
    description: 'Displays all Marketplace trades to and from a specifed address'
  }
];
const selectedMode = ref('Coins');
const alertMetrics = computed(() => {
  return selectedMode.value == 'Coins' ? coinAlertMetrics : nftAlertMetrics;
});
function resetAlertData() {
  alertData.value = {
    type: selectedMode.value == 'Coins' ? 'coin_transfer_all' : 'nft_transfer_all',
    chain: 'ethereum',
    protocols: [],
    addresses: {
      tag: 'Address',
      address: null,
      address_tags: [],
      folder: []
    },
    coins: [],
    watchlist: { id: null },
    category: { id: null },
    subcategory: { id: null },
    value: null,
    collections: []
  };
}

//ALERT COINS/NFT TAGS SELECTION
const coinAlertTags = ref({ coins: 'Coins', watchlist: 'Watchlist', category: 'Category', subcategory: 'Subcategory' });
const nftAlertTags = ref({ all: 'All', top_50: 'Top 50' });
const selectedAlertTag = ref(null);
onMounted(() => {
  $emitter.$emit('config:alert', { config: alertData.value });
  selectedMode.value = coinAlertMetrics.find(m => m.id == alertData.value.type) ? 'Coins' : 'NFTs';
  if (props.alert) {
    let alertConfig = props.alert.config;
    alertData.value = { ...alertData.value, ...props.alert.config };
    for (const [k, v] of Object.entries(alertConfig)) {
      if (
        (['watchlist', 'category', 'subcategory'].includes(k) && v && (v.uid != null || v.id != null)) ||
        (['coins', 'collections'].includes(k) && v && v.length)
      ) {
        selectedAlertTag.value = coinAlertTags.value[k];
      }
    }
    if (!selectedAlertTag.value) selectedAlertTag.value = 'Coins';
  } else {
    selectedAlertTag.value = selectedMode.value == 'Coins' ? 'Coins' : 'All';
  }
});
watch(selectedAlertTag, (newVal, oldVal) => {
  if (oldVal) {
    alertData.value = {
      ...alertData.value,
      coins: [],
      watchlist: { id: null },
      category: { id: null },
      subcategory: { id: null },
      collections: []
    };
  }
});
watch(selectedMode, () => {
  selectedAlertTag.value = selectedMode.value == 'Coins' ? 'Coins' : 'All';
});

//CHAINS
const allChains = computed(() => {
  return $store.state.supportedChains.map(chain => {
    return {
      id: chain.id,
      label: chain.text
    };
  });
});
const onchainCoins = ref({});
async function fetchOnchainCoins() {
  const resp = (await $http.get('/data/onchain_coins')).data;
  (resp || []).forEach(coin => {
    const chains = allChains.value.map(c => c.id);
    chains.forEach(chain => {
      if (coin[chain + '_address']) {
        if (!onchainCoins.value[chain]) {
          onchainCoins.value[chain] = [];
        }
        onchainCoins.value[chain].push(coin);
      }
    });
  });
}
const selectedChainCoins = computed(() => {
  return onchainCoins.value[alertData.value.chain]?.length
    ? onchainCoins.value[alertData.value.chain]
    : [{ coin_uid: 'none' }];
});
const filteredChainCoins = computed(() => {
  return $store.getters.coins.filter(c => selectedChainCoins.value.some(coin => coin.uid == c.coin_uid));
});
onMounted(() => {
  fetchOnchainCoins();
  fetchCategories();
});

//COINS
const coins = computed(() => $store.getters.coins);
const isDataReady = ref({ watchlist: false, category: false, coins: false });
const preSelectedCoins = computed(() => {
  if (alertData.value.coins && coins.value) {
    return coins.value.filter(x => alertData.value.coins.includes(x.coin_uid)) || [];
  }
  return [];
});
watch(
  () => coins.value,
  () => {
    if (coins.value) {
      isDataReady.value.coins = true;
    }
  },
  { immediate: true }
);
onMounted(() => {
  if (coins.value.length) {
    isDataReady.value.coins = true;
  }
});

//ADDRESSES
onBeforeMount(() => {
  $store.dispatch('setOnchainAddresses');
});
const onchainTieAddresses = ref([]);
const onchainAddresses = computed(() => {
  return $store.getters.onchainAddresses || [];
});
const myTags = computed(() => {
  return onchainAddresses.value.map(address => {
    return { id: address.id, label: address.label, address: address.address, category: 'team' };
  });
});
const selectedAddressTags = computed(() => {
  return alertData.value.addresses.address_tags.map(x => x.id);
});
function updateAlertAddresses(tags, entity) {
  if (alertData.value.addresses[entity].length > tags.length) {
    alertData.value.addresses[entity] = alertData.value.addresses[entity].filter(x => tags.includes(x.id));
  } else if (alertData.value.addresses[entity].length < tags.length) {
    let allEntities = {
      Folders: allFolders.value,
      Exchanges: exchangeTags.value,
      ENS: ensTags.value,
      'All Tags': allTags.value,
      'My Tags': myTags.value
    };

    let id = tags[tags.length - 1];
    let selectedEntity = allEntities[alertData.value.addresses?.tag].find(x => x.id == id);
    let entityObject = {
      id: id,
      label: selectedEntity?.label,
      category: selectedEntity?.category
    };
    alertData.value.addresses[entity].push(entityObject);
  }
}
const allTags = ref([]);
async function fetchAllTags(search) {
  const response = await getAddresses({ onchain_tags: true, chain: alertData.value.chain, search: search });
  let exchangeExists = [];
  onchainTieAddresses.value = response
    .map(tag => {
      let index = exchangeTags.value.findIndex(x => x.id == tag.tag);
      if (index == -1) {
        return { id: tag.tag, label: tag.tag, category: tag.category };
      } else if (!exchangeExists[tag.tag]) {
        //AGGREGATION OF EXCHANGES
        exchangeExists[tag.tag] = true;
        return exchangeTags.value[index];
      }
    })
    .filter(x => x !== undefined);
  allTags.value = myTags.value.filter(tag => tag.label.includes(search))?.concat(onchainTieAddresses.value);
  return allTags.value?.sort((a, b) => a.label?.localeCompare(b.label, undefined, { numeric: true }));
}
onMounted(async () => {
  if (alertData.value.addresses?.tag == 'Folders') {
    (alertData.value.folders || []).forEach(folder => {
      folder.label = allFolders.value.find(x => x.id == folder.id)?.label || folder.label;
    });
  } else if (['All Tags', 'My Tags'].includes(alertData.value.addresses?.tag)) {
    (alertData.value.addresses.address_tags || []).forEach(address_tag => {
      if (address_tag.category == 'team') {
        address_tag.label = myTags.value.find(x => x.id == address_tag.id)?.label || address_tag.label;
      }
    });
  }
});

//EXCHANGE TAGS
const exchangeTags = ref([]);
onMounted(() => {
  fetchExchanges();
});
async function fetchExchanges() {
  const response = await getAddresses({
    onchain_tags: true,
    chain: alertData.value.chain,
    category: 'exchange'
  });
  let uniqueExchanges = [...new Set(response.map(x => x.tag))];
  let exchanges = uniqueExchanges.map(tag => {
    return { id: tag, label: capitalize(tag), category: 'exchange' };
  });
  exchangeTags.value = exchanges?.sort((a, b) => a.label?.localeCompare(b.label, undefined, { numeric: true }));
}

//ENS TAGS
const ensTags = ref([]);
async function fetchEnsTags(search) {
  const response = await getAddresses({
    onchain_tags: true,
    chain: alertData.value.chain,
    search: search,
    category: 'ens'
  });
  ensTags.value = response.map(tag => {
    return { id: tag.tag, label: tag.tag, category: tag.category };
  });
  return ensTags.value?.sort((a, b) => a.label?.localeCompare(b.label, undefined, { numeric: true }));
}
function fetchTags(search) {
  if (!search || search.length == 0) return [];
  if (alertData.value.addresses.tag == 'ENS') {
    return fetchEnsTags(search);
  } else if (alertData.value.addresses.tag == 'All Tags') {
    return fetchAllTags(search);
  }
}

//FOLDERS
const allFolders = computed(() => {
  return ($watchlistStore.onchainFolders || []).map(f => {
    return { id: f.id, label: f.name };
  });
});
const selectedFolders = computed(() => {
  return alertData.value.addresses.folders.map(x => x.id);
});

//CATEGORIES AND SUBCATEGORIES
const allCategories = ref([]);
const allSubcategories = ref([]);
async function fetchCategories() {
  var allCoins = coins.value.map(c => c.coin_uid);
  allCategories.value = $store.state.categories.coin
    .filter(cat => cat.id !== 0)
    .map(cat => {
      var coin_uids = cat.flat_coin_uids?.filter(uid => allCoins.includes(uid));
      return { id: cat.id, label: cat.name, text: cat.name, coin_uids: coin_uids || [] };
    });

  $store.state.categories.coin_sub_categories
    .filter(sub => sub.id !== 0)
    .forEach(sub => {
      var coin_uids = sub.flat_coin_uids?.filter(uid => allCoins.includes(uid));

      allSubcategories.value.push({
        id: sub.id,
        label: sub?.name,
        coin_uids: coin_uids || []
      });
    });

  allSubcategories.value = allSubcategories.value.sort((a, b) => (a.label > b.label ? 1 : -1));
  isDataReady.value.category = true;
}
const filteredCategories = computed(() => {
  const chainCoinUids = filteredChainCoins.value.map(c => c.uid);
  return allCategories.value.filter(s => s.coin_uids.some(c => chainCoinUids.includes(c)));
});
const filteredSubcategories = computed(() => {
  const chainCoinUids = filteredChainCoins.value.map(c => c.uid);
  return allSubcategories.value.filter(s => s.coin_uids.some(c => chainCoinUids.includes(c)));
});

//WATCHLISTS
const allWatchlists = computed(() =>
  $watchlistStore.coins.map(list => ({
    id: list.id,
    label: list.name,
    text: list.name,
    coin_uids: list.entities_uids
  }))
);
const watchlistsReady = computed(() => $watchlistStore.watchlistLoader.coins);

watch(watchlistsReady, () => {
  if (watchlistsReady.value) isDataReady.value.watchlist = true;
});

onMounted(() => {
  isDataReady.value.watchlist = watchlistsReady.value;
});

const filteredWatchlists = computed(() => {
  const chainCoinUids = filteredChainCoins.value.map(c => c.uid);
  return allWatchlists.value.filter(w => w.coin_uids.some(c => chainCoinUids.includes(c)));
});
watch(
  () => alertData.value.watchlist?.id,
  () => {
    if (isDataReady.value.watchlist) {
      let watchlist = filteredWatchlists.value.find(w => w.id == alertData.value.watchlist.id);
      alertData.value.watchlist = watchlist ? { id: watchlist.id, name: watchlist.label } : { id: null };
    }
  }
);
watch(filteredWatchlists, (newVal, oldVal) => {
  if (oldVal.length || newVal.length) {
    let watchlist = filteredWatchlists.value.find(w => w.id == alertData.value.watchlist.id);
    alertData.value.watchlist = watchlist ? { id: watchlist.id, name: watchlist.label } : { id: null };
  }
});

//TOGGLES
const toggles = ref({
  address: true,
  coins: true,
  protocol: true,
  value: true
});

//REQUIRED FIELDS
const required = ref({ alertType: false });
const invalidAddress = ref(false);
const errorStatement = computed(() => {
  return `Please select at least one of ${alertData.value.type == 'dex_trade' ? 'Protocol, ' : ''} Address or ${
    selectedMode.value == 'Coins' ? 'Coin' : 'Collection'
  }`;
});
function showValidationError(data) {
  if (data.alertType) {
    required.value.alertType = data.alertType;
    setTimeout(() => {
      required.value.alertType = false;
    }, 5000);
  } else if (data.invalidAddress) {
    invalidAddress.value = data.invalidAddress;
    setTimeout(() => {
      invalidAddress.value = false;
    }, 5000);
  }
}
onMounted(() => {
  $emitter.$on('OnchainTransactionForm', data => {
    showValidationError(data);
  });
});

watch(filteredChainCoins, (newVal, oldVal) => {
  if (isEqual(newVal, oldVal)) return;
  if (selectedAlertTag.value == 'Coins') {
    let filteredCoins = filteredChainCoins.value.filter(x => alertData.value.coins.includes(x.uid));
    if (!filteredCoins.length) {
      alertData.value.coins = [];
    }
  } else if (selectedAlertTag.value == 'Watchlist') {
    let filteredWatchlist = filteredWatchlists.value.find(x => alertData.value.watchlist?.id == x.id);
    if (!filteredWatchlist) {
      alertData.value.watchlist = { id: null };
    }
  } else if (selectedAlertTag.value == 'Category') {
    let category = filteredCategories.value.find(x => alertData.value.category?.id == x.id);
    if (!category) {
      alertData.value.category = { id: null };
    }
  } else if (selectedAlertTag.value == 'Subcategory') {
    let subcategory = filteredSubcategories.value.find(x => alertData.value.subcategory?.id == x.id);
    if (!subcategory) {
      alertData.value.subcategory = { id: null };
    }
  }
});
</script>
